import React, { useState } from 'react';
import { View, Text, Image, TouchableHighlight, Linking } from 'react-native';
import { styles } from './App';
import './App.css';

function CanceledMessage() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const zone = urlParams.get('zone');
  const plateNum = urlParams.get('plate');
  const getZone = '/get-zone?zone=' + zone + '&plate=' + plateNum;

  return (
    <View style={styles.topLevelContainer}>
      <Image 
        style={styles.mainImage} 
        source={process.env.PUBLIC_URL + '/images/ParkIt/logo.png'} />
      <View style={styles.space} />
      <Text style={styles.headingLeft}>
        Payment was canceled{'\n'}
      </Text>
      <Text style={styles.text}>
        Still need parking? Click below.
      </Text>
      <View style={styles.space} />
      <TouchableHighlight onPress={() => window.location.assign(getZone)}>
        <View style={styles.roundedWideButton}>
          <Text style={styles.buttonText}>Start Over</Text>
        </View>
      </TouchableHighlight>
      <Text style={styles.hyperlink}
        onPress={() => Linking.openURL('/privacy-policy')}>
        <u>Privacy Policy</u>
      </Text>
    </View>
  )
}

export default CanceledMessage;