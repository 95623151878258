import './App.css';
import { styles, APIButton } from './App';
import React, { useState, useEffect } from 'react';
import { View, Text, Image, TextInput, TouchableHighlight, Modal, Pressable, Linking } from 'react-native';
import { useNavigate } from 'react-router-dom';

const getZoneAPI = process.env.REACT_APP_GET_ZONE_URL;

function GetZone() {
  const navigate = useNavigate();
  const [zone, setZone] = useState(0);
  const [plateNumber, setPlateNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState("Zone number and license plate number must be set in order to continue");
  const [qZone, setQZone] = useState(null);
  const [qPlateNum, setQPlateNum] = useState(null);
  const [qTextSale, setQTextSale] = useState('');
  const [zonePlaceholder, setZonePlaceholder] = useState('Zone Number');
  const [platePlaceholder, setPlatePlaceholder] = useState('Plate Number');
  
  function parseZoneData(data) {
    setLoading(false);
    navigate('/get-duration', { 
      state: {
        'zone': zone, 
        'plateNumber': plateNumber.toUpperCase(),
        'state_code': data['state_code'],
        'increment': data['increment'],
        'sales_tax': JSON.parse(data['sales_tax']),
        'price_per_hour': JSON.parse(data['price_per_hour']),
        'max_time': data['max_time'],
        'parkit_fee': data['parkit_fee'],
        'transaction_fee': data['transaction_fee'],
        'text_sale': qTextSale,
      } 
    });
  }

  function getZoneInfo(zone) {
    setLoading(true);
  
    fetch(getZoneAPI + '?' + new URLSearchParams( { requestType: 'get_zone', zoneNumber: zone } ), {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
    })
    .then((res) => res.json())
    .then((data) => {
      if (data !== "-1")
      {
        parseZoneData(data);
      }
      else
      {
        // invalid case
        var error = "Invalid zone number entered, please enter a valid zone number";
        setLoading(false);
        setModalVisible(true);
        setModalText(error);
      }
    })
  }

  function handleSubmit() {
    var plate_match = "^\s*([0-9a-zA-Z-]+)\s*$";
    if (isNaN(zone))
    {
      setModalText("Zone number must be a number to continue");
      setModalVisible(true);
    }
    else if (zone.length === 0)
    {
      setModalText("Zone number must be set in order to continue");
      setModalVisible(true);
    }
    else if (plateNumber.length === 0)
    {
      setModalText("Plate number must be set in order to continue");
      setModalVisible(true);
    }
    else if (plateNumber.search(plate_match) === -1)
    {
      setModalText("Please enter a valid license plate number to continue");
      setModalVisible(true);
    }
    else
    {
      getZoneInfo(zone)
    }
  }

  useEffect(() => {
    // will run on zone and platenum update
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setQZone(urlParams.get('zone'));
    setQPlateNum(urlParams.get('plate'));
    setQTextSale(urlParams.get('text'));
    if (qZone !== null)
    {
      setZonePlaceholder(qZone);
      setZone(parseInt(qZone));
    }
    if (qPlateNum !== null)
    {
      setPlatePlaceholder(qPlateNum);
      setPlateNumber(qPlateNum);
    }
  }, [qZone, qPlateNum, qTextSale])

  return (
    <View style={styles.topLevelContainer}>
      <Image 
        style={styles.mainImage} 
        source={process.env.PUBLIC_URL + '/images/ParkIt/logo.png'} />
      <View style={styles.space} />
      <Text style={styles.headingLeft}>Enter zone & license plate number </Text>
      <View style={styles.horizontalGroup}>
        <TextInput
          style={styles.inputCenter}
          onChangeText={setZone}
          placeholder={zonePlaceholder}
          keyboardType='numeric'
        />
        <TextInput
          style={styles.inputCenter}
          onChangeText={setPlateNumber}
          placeholder={platePlaceholder}
          keyboardType='default'
        />
      </View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.lightText}>{modalText}</Text>
            <View style={styles.space} />
            <Pressable
              style={styles.roundedWideButtonLight}
              onPress={() => setModalVisible(!modalVisible)}>
              <Text style={styles.buttonTextDark}>Go Back</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
      <TouchableHighlight onPress={handleSubmit}>
        <View style={styles.roundedWideButton}>
          <Text style={styles.buttonText}><APIButton condition={loading} defaultText={'Continue'} color={'#8BDEFF'}/></Text>
        </View>
      </TouchableHighlight>
      <Text style={styles.hyperlink}
        onPress={() => Linking.openURL('/privacy-policy')}>
        <u>Privacy Policy</u>
      </Text>
    </View>
  );
}

export default GetZone;