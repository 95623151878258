import React, { useState, useEffect } from 'react';
import { View, Text, Image, Picker, TouchableHighlight, Pressable, Modal, TextInput, Switch, Linking } from 'react-native';
import './App.css';
import { styles, APIButton } from './App';
import { useLocation } from 'react-router-dom';
import PhoneInput from 'react-native-phone-input';

const paymentRequestAPI = process.env.REACT_APP_REQ_PY_URL;

function parseCheckoutData(data)
{
  window.sessionStorage.setItem('increment', data['metadata']['increment']);
  window.sessionStorage.setItem('price', data['metadata']['price']);
  window.sessionStorage.setItem('state_code', data['metadata']['state_code']);
  window.sessionStorage.setItem('max_time', data['metadata']['max_time']);
  window.sessionStorage.setItem('zone', data['metadata']['zone']);
  window.sessionStorage.setItem('plateNumber', data['metadata']['plateNumber']);
  window.sessionStorage.setItem('duration', data['metadata']['duration']);
  window.sessionStorage.setItem('subtotal', data['subtotal']);
  window.sessionStorage.setItem('amount_total', data['amount_total']);
  window.sessionStorage.setItem('total_details', JSON.stringify(data['total_details']));
  window.sessionStorage.setItem('checkout_id', data['id']);
  window.sessionStorage.setItem('phone_number', data['metadata']['phone'].getValue());
  window.sessionStorage.setItem('parkit_fee', data['metadata']['parkit_fee']);
  window.sessionStorage.setItem('transaction_fee', data['metadata']['transaction_fee']);
  window.sessionStorage.setItem('promo_code', data['metadata']['promo_code']);
  window.location.assign(data['url']);
}

function GetDuration() {
  const [hours, setHours] = useState(0.00);
  const [minutes, setMinutes] = useState(0.00);
  const [loading, setLoading] = useState(false);
  const [promoLoading, setPromoLoading] = useState(false);
  const [phoneEntry, setPhoneEntryVisible] = useState(false);
  const [invalidConfig, setInvalidConfig] = useState(false);
  const [invalidConfigText, setInvalidConfigText] = useState('');
  const [invalidNumber, setInvalidNumber] = useState('');
  const [invalidNumberText, setInvalidNumberText] = useState('');
  const [invalidNumberUpdate, setInvalidNumberUpdate] = useState('');
  const [phone, setPhone] = useState("");
  const [promoDiscount, setPromoDiscount] = useState(null);
  const [promoPlaceholder, setPromoPlaceholder] = useState('Promo Code');
  const [promoStart, setPromoStart] = useState(null);
  const [promoEnd, setPromoEnd] = useState(null);
  const [promoFixedFee, setPromoFixedFee] = useState(null);
  const [promoFee, setPromoFee] = useState(null);
  const [promoFixedTime, setPromoFixedTime] = useState(null);
  const [promoFixedTransFee, setPromoFixedTransFee] = useState(null);
  const [promoTransFee, setPromoTransFee] = useState(null);
  const [promoCode, setPromoCode] = useState('');
  const [switchEnabled, setSwitchEnabled] = useState(false);
  const toggleSwitch = () => setSwitchEnabled(previousState => !previousState);
  
  // TODO: consider moving away from useLocation
  const state = useLocation();
  const [zone, setZone] = useState(null);
  const [taxEnabled, setTaxEnabled] = useState(null);
  const [plateNumber, setPlateNumber] = useState(null);
  const [increment, setIncrement] = useState(null);
  const [price_per_hour, setPricePerHour] = useState(null);
  const [price_per_minute, setPricePerMinute] = useState(null);
  const [price, setPrice] = useState(null);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [promoApplied, setPromoApplied] = useState('');
  const [state_code, setStateCode] = useState(null);
  const [max_time, setMaxTime] = useState(null);
  const [parkit_fee, setParkItFee] = useState(null);
  const [transaction_fee, setTransactionFee] = useState(null);
  const [zone_transaction_fee, setZoneTransactionFee] = useState(null);
  const [text_sale, setTextSale] = useState('');
  const [phoneEntryMsg, setPhoneEntryMsg] = useState("Opt in to receive a text when it's time to extend your parking session");
  const [phoneHelpMsg, setPhoneHelpMsg] = useState("Include country code (+1 for US)")

  if (!state.state) {
    // now we know we need to collect zone and/or plateNumber from the user
    window.location.assign('/get-zone');
  }

  useEffect(() => {
    setZone(state.state['zone']);
    setPlateNumber(state.state['plateNumber']);
    setIncrement(state.state['increment']);
    setPricePerHour(state.state['price_per_hour']);
    setStateCode(state.state['state_code']);
    setMaxTime(state.state['max_time']);
    setParkItFee(state.state['parkit_fee']);
    setZoneTransactionFee(state.state['transaction_fee']);
    setTaxEnabled(state.state['sales_tax']);
    setTextSale(state.state['text_sale']);
  }, [state])

  useEffect(() => {
    if (price_per_hour == null || minutes === null || hours === null)
    {
      // have to wait for it, do nothing
      return;
    }

    var tmp_transaction_fee = promoFixedTransFee && switchEnabled && (promoTransFee !== null) ? promoTransFee : zone_transaction_fee;
    setTransactionFee(tmp_transaction_fee);
    setParkItFee(tmp_transaction_fee);
    var total_price = calculatePrice(price_per_hour, tmp_transaction_fee, minutes, hours);
    
    if (switchEnabled && promoDiscount !== null)
    {
      setPrice(total_price * (1 - promoDiscount));
      let promo_str = '(-' + (promoDiscount*100).toFixed(2) + '%)';
      setPromoApplied(promo_str);
    }
    else if (switchEnabled && promoFixedFee)
    {
      if (promoFixedTime)
      {
        let curr_date = new Date();
        let curr_hour = curr_date.getHours();
        let curr_min = curr_date.getMinutes();
        let curr_time = curr_hour + ( (curr_min * (100.0/60.0)) / 100.0 ); // get hour with decimal minutes
        var remaining_hours = promoEnd - curr_time;
        var start_hours = curr_hour - promoStart;
      
        // ensure we are within the promo bounds
        if (remaining_hours > 0 && start_hours >= -0.5)
        {
          let savings = (promoFee/100) - (total_price/100);
          setPrice(promoFee + tmp_transaction_fee);
          if (savings < 0)
          {
            // only display savings when it's cheaper than parking
            setPromoApplied('(' + savings.toFixed(2) + ')');
          }
          else
          {
            setPromoApplied('(Applied!)');
          }
          let new_hours = Math.ceil(remaining_hours);
          setHours(new_hours);
          setMinutes(0); // since we are ceiling remaining_hours, there are no minutes
        }
        else
        {
          // Past time for the fixed time promo
          setPrice(total_price)
          setPromoApplied('');  
        }
      }
      else
      {
        // No promo fixed time
        let savings = (promoFee/100) - (total_price/100);
        setPrice(promoFee + tmp_transaction_fee);
        if (savings < 0)
        {
          // only display savings when it's cheaper than parking
          setPromoApplied('(' + savings.toFixed(2) + ')');
        }
        else
        {
          setPromoApplied('(Applied!)');
        }
      }
      
    }
    else
    {
      setPrice(total_price)
      setPromoApplied('');
    }
  }, [price_per_minute, price_per_hour, minutes, hours, promoDiscount, promoFixedFee,
      promoFee, switchEnabled, promoFixedTime, zone_transaction_fee, promoTransFee,
      promoFixedTransFee, promoStart, promoEnd])

  useEffect(() => {
    setEstimatedPrice((price / 100.00).toFixed(2));
  }, [price])

  function calculatePrice(price_per_hour, tmp_transaction_fee, minutes, hours)
  {
    var total_price = 0.00;
    var time_now  = new Date();
    var time_end  = new Date();
    var day_now   = time_now.getDay();
    var hour_now  = time_now.getHours();
    // var mins_now  = time_now.getMinutes;
    time_end.setHours(time_end.getHours() + +hours);
    time_end.setMinutes(time_end.getMinutes() + +minutes);

    var time_diff = Math.ceil(Math.abs(time_end - time_now) / 36e5); // diff in hours
    var prices_today    = price_per_hour[day_now];
    var prices_next     = price_per_hour[(day_now+1)%7];
    var hours_end       = (hour_now + time_diff) % 25;
    var rollover        = (hours_end < hour_now) ? true : false;
    var rollover_hours  = 0;
    if (rollover)
    {
      rollover_hours = hours_end + 1;
      time_diff -= rollover_hours;
    }

    for (var i = hour_now; (i < 24 && i < hour_now + time_diff); i++)
    {
      total_price += prices_today[i];
    }
    for (var k = 0; (k < 24 && k < rollover_hours); k++)
    {
      // comparison is < rollover + 1 since the hours start at 0
      total_price += prices_next[k];
    }
    total_price += tmp_transaction_fee;
    if (total_price === tmp_transaction_fee)
    {
      // if we are only charging the transaction fee, wavie it
      total_price = 0;
    }
    return total_price;
  }

  function parsePromoData(data)
  {
    let valid_zones = JSON.parse(data['zones']);  
    if ( !valid_zones.includes(parseInt(zone)) )
    {
      setPromoLoading(false);
      setInvalidConfigText('Promo does not apply to your zone');
      setInvalidConfig(true);
      return;
    }

    setPromoFixedFee(data['fixed_fee']);
    setPromoFixedTime(data['fixed_time']);
    setPromoFixedTransFee(data['fixed_transaction_fee']);

    if (!data['fixed_fee'])
    {
      // if there is no fixed fee, we have a discount
      setPromoDiscount(data['discount']);
    }
    else
    {
      // if there is a fixed fee, we should apply it
      setPromoFee(data['fee']);
    }
    if (data['fixed_time'])
    {
      // if there is a fixed time, record it
      setPromoStart(data['start_daily']);
      setPromoEnd(data['end_daily']);
    }
    if (data['fixed_transaction_fee'])
    {
      setPromoTransFee(data['transaction_fee']);
    }
  }

  function onCheckout(zone, plateNumber, price, hours, minutes) {
    setLoading(true);
    window.sessionStorage.setItem('price_per_hour', JSON.stringify(price_per_hour));

    let checkoutStr = "Zone " + zone + " Parking";
    const checkoutParams = {
      type: 'checkout_link',
      name: checkoutStr,
      increment: increment,
      sales_tax: (taxEnabled === true) ? 1 : 0,
      state_code: state_code,
      max_time: max_time,
      zone: zone,
      plateNumber: plateNumber,
      parkit_fee: parkit_fee,
      transaction_fee: transaction_fee,
      price: parseInt(price.toFixed(2)), 
      duration: {hours: hours, minutes: minutes},
      send_text: (window.sessionStorage.getItem('send_text') === 'true') ? 1 : 0,
      phone_number: phone.getValue(),
      environment: process.env.REACT_APP_ENVIRONMENT,
      promo_code: promoCode,
      text_sale: (text_sale === 'y') ? 1 : 0,
      domain: window.location.origin,
    }
  
    fetch(paymentRequestAPI, {
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(checkoutParams),
    })
    .then((res) => res.json())
    .then((data) => {
      data['metadata']['phone'] = phone;
      setLoading(false);
      parseCheckoutData(data)
    })
  }

  function startPayment() {
    onCheckout(zone, plateNumber, price, hours, minutes);
  }

  function renderMinutes() {
    var items = [];
    
    // check if minutes shouldn't be added
    let hours_in_mins = +hours * 60;
    if (hours_in_mins >= +max_time) {
      // cannot exceed max time
      return [<Picker.Item key={0} label='0 Minutes' value={0} />];
    }
  
    // need to add minimum pricing logic (think it's $0.50 for Stripe)
    var num_increments = 60 / increment;
    for (var i = 0; i < num_increments; i++) {
      let increment_val = i * increment;
      let label_val = increment_val + ' Minutes';

      items.push( <Picker.Item key={i} label={label_val} value={increment_val} /> )
    }

    return items;
  }

  function renderHours() {
    var items = [];
    
    // no hours needed, just return empty
    if (max_time < 60) {
      return [<Picker.Item key={0} label='0 Hours' value={0} />];
    }
    
    var max_hours = (max_time / 60);
    
    // promos override max hours, so need to account for that
    if (promoApplied)
    {
      let curr_date = new Date();
      let curr_hour = curr_date.getHours();
      let curr_min = curr_date.getMinutes();
      let curr_time = curr_hour + ( (curr_min * (100.0/60.0)) / 100.0 ); // get hour with decimal minutes
      let remaining_hours = promoEnd - curr_time;
      if (max_hours < remaining_hours)
      {
        max_hours = Math.ceil(remaining_hours);
      }
    }
    

    for (var i = 0; i <= max_hours; i++) {
      let label_val = i + ' Hours';
      items.push( <Picker.Item key={i} label={label_val} value={i} /> )
    }

    return items;
  }

  function handleSetHours(itemValue) {
    // check if minutes shouldn't be added
    let hours_in_mins = +itemValue * 60;
    if (hours_in_mins >= +max_time) {
      // cannot exceed max time
      setMinutes(0);
    } 
    setHours(itemValue);
  }

  function skipPhoneEntry()
  {
    setPhoneEntryVisible(false);
    setInvalidNumber(false);
    window.sessionStorage.setItem('send_text', false);
    startPayment();
  }

  function handleUpdatedNumber()
  {
    setInvalidNumber(false);
    setInvalidConfig(false);
    setPhoneEntryVisible(false);
    phone.setValue(invalidNumberUpdate);
    window.sessionStorage.setItem('send_text', true);
    startPayment();
  }

  function handlePhoneEntry()
  {
    if (phone.isValidNumber())
    {
      setInvalidConfig(false);
      setPhoneEntryVisible(false);
      setPhone(phone);
      window.sessionStorage.setItem('send_text', true);
      startPayment();
    }
    else
    {
      let num = phone.getValue().replace('+', '');
      if ( num.length === 10 )
      {
        let full_num = '+1' + num;
        let format_num = '+1 (' + num.substring(0,3) + ') ' + num.substring(3, 6) + '-' + num.substring(6, 10);
        let update = 'Please confirm your number:\n\n' + format_num;
        setInvalidNumberUpdate(full_num);
        setInvalidNumberText(update);
        setInvalidNumber(true);
      }
      else
      {
        setInvalidConfigText('Please enter a valid number to continue. Remember to start US numbers with +1.');
        setInvalidConfig(true);
      }
    }
  }

  function handleSubmit()
  {
    if (hours === 0 && minutes === 0)
    {
      setInvalidConfigText('Please enter a parking duration');
      setInvalidConfig(true);
    }
    else
    {
      setPhoneEntryVisible(true);
    }
  }

  function resetPromoData()
  {
    setPromoFixedFee(null);
    setPromoFee(null);
    setPromoDiscount(null);
    setPromoEnd(null);
    setPromoStart(null);
    setPromoFixedTime(null);
    setPromoFixedTransFee(null);
    setPromoTransFee(null);
  }

  function handlePromo(promo)
  {
    if (promo === '')
    {
      setInvalidConfigText('No promo code entered!');
      setInvalidConfig(true);
      return;
    }
    setPromoLoading(true);
    promo = promo.toUpperCase();
    setPromoCode(promo);

    fetch(paymentRequestAPI + '?' + new URLSearchParams( { requestType: 'get_promo', code: promo } ), {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
    })
    .then((res) => res.json())
    .then((data) => {
      if (data !== "-1")
      {
        resetPromoData();
        setPromoPlaceholder(promo);
        parsePromoData(data[0]);
        setPromoLoading(false);
      }
      else
      {
        setPromoLoading(false);
        setInvalidConfigText('Invalid promo code');
        setInvalidConfig(true);
      }
    })
  }

  function PromoView(props)
  {
    let tmp_promo = "";
    function updatePromoCode(data)
    {
      tmp_promo = data;
    }
    if (props.enabled)
    {
      return (
        <View>
          <View style={styles.horizontalGroup}>
            <TextInput
              style={styles.promoInput}
              onChangeText={updatePromoCode}
              placeholder={promoPlaceholder}
              keyboardType='default'
            />
            <TouchableHighlight onPress={() => handlePromo(tmp_promo)} style={styles.promoApply}>
              <View>
                <Text style={styles.buttonText}><APIButton condition={promoLoading} defaultText={'Apply'}/></Text>
              </View>
            </TouchableHighlight>
          </View>
          <View style={styles.space} />
        </View>
      )
    }
  }

  return (
    <View style={styles.topLevelContainer}>
      <View>
        <Image 
          style={styles.mainImage} 
          source={process.env.PUBLIC_URL + "/images/ParkIt/logo.png"} />
      </View>
      <View style={styles.space} />
      <View>
        <Text style={styles.headingLeft}>How long would you like to park?</Text>
        <View style={styles.space} />
        <View style={styles.horizontalGroup}>
          <Picker 
            style={styles.dropDown}
            value={hours}
            onValueChange={(itemValue, itemIndex) => handleSetHours(parseInt(itemValue))} >
            { renderHours() }
          </Picker>
          <View style={styles.space} />
          <Picker 
            style={styles.dropDown}
            vale={minutes}
            onValueChange={(itemValue, itemIndex) => setMinutes(parseInt(itemValue))}>
            { renderMinutes() }
          </Picker>
          <Modal
            animationType="slide"
            transparent={true}
            visible={phoneEntry}
            onRequestClose={() => {
              setPhoneEntryVisible(!phoneEntry);
            }}>
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Text style={styles.lightText}>{phoneEntryMsg}</Text>
                <View style={styles.space} />
                <View style={styles.space} />
                <PhoneInput
                  ref={(ref) => { setPhone(ref) }}
                  initialCountry={'us'}
                  initialValue={''}
                  textStyle={styles.phoneNumberEntry}
                />
                <View style={styles.space} />
                <Text style={styles.lightTextSmall}>{phoneHelpMsg}</Text>
                <View style={styles.space} />
                <View style={styles.space} />
                <View style={styles.horizontalGroup}>
                  <Pressable
                    style={styles.roundedWideButtonLight}
                    onPress={() => handlePhoneEntry()}>
                    <Text style={styles.buttonTextDark}><APIButton condition={loading} defaultText={'Opt in'} color={'#26466F'}/></Text>
                  </Pressable>
                  <View style={styles.space} />
                  <Pressable
                    style={styles.roundedWideButtonLight}
                    onPress={() => skipPhoneEntry()}>
                    <Text style={styles.buttonTextDark}>Skip</Text>
                  </Pressable>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="slide"
            transparent={true}
            visible={invalidConfig}
            onRequestClose={() => {
              setInvalidConfig(!invalidConfig);
            }}>
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Text style={styles.lightText}>{invalidConfigText}</Text>
                <View style={styles.space} />
                <View style={styles.space} />
                <View style={styles.horizontalGroup}>
                  <Pressable
                    style={styles.roundedWideButtonLight}
                    onPress={() => setInvalidConfig(!invalidConfig)}>
                    <Text style={styles.buttonTextDark}><APIButton condition={loading} defaultText={'Ok'} color={'#26466F'}/></Text>
                  </Pressable>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="slide"
            transparent={true}
            visible={invalidNumber}
            onRequestClose={() => {
              setInvalidNumber(!invalidNumber);
            }}>
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Text style={styles.lightText}>{invalidNumberText}</Text>
                <View style={styles.space} />
                <View style={styles.space} />
                <View style={styles.horizontalGroup}>
                  <Pressable
                    style={styles.roundedWideButtonLight}
                    onPress={() => handleUpdatedNumber()}>
                    <Text style={styles.buttonTextDark}><APIButton condition={loading} defaultText={'Confirm'} color={'#26466F'}/></Text>
                  </Pressable>
                  <View style={styles.space} />
                  <Pressable
                    style={styles.roundedWideButtonLight}
                    onPress={() => setInvalidNumber(!invalidNumber)}>
                    <Text style={styles.buttonTextDark}><APIButton condition={loading} defaultText={'Back'} color={'#26466F'}/></Text>
                  </Pressable>
                </View>
              </View>
            </View>
          </Modal>
        </View>
        <View style={styles.space} />
        <View style={styles.horizontalLeft} visibile={switchEnabled}>
          <Text style={styles.promoText}>Add a promo code or voucher</Text>
          <Switch
            onValueChange={toggleSwitch}
            value={switchEnabled}
            style={styles.promoSwitch}
          />
        </View>
        <View style={styles.space} />
        <PromoView enabled={switchEnabled}/>
        <View style={styles.horizontalLeft}>
          <Text style={styles.headingLeft}>Estimated cost: ${estimatedPrice}</Text>
          <View style={styles.space}/>
          <Text style={styles.promoDiscount}>{promoApplied}</Text>
        </View>
        <View style={styles.space} />
        <TouchableHighlight onPress={() => handleSubmit()} >
          <View style={styles.roundedWideButton}>
            <Text style={styles.buttonText}><APIButton condition={loading} defaultText={'Continue'}/></Text>
          </View>
        </TouchableHighlight>
        <Text style={styles.hyperlink}
          onPress={() => Linking.openURL('/privacy-policy')}>
          <u>Privacy Policy</u>
        </Text>
      </View>
    </View>
  );
}

export default GetDuration;