import React from 'react';
import { View, Text, Image } from 'react-native';
import { styles } from './App';
import './App.css';
import { Navbar } from './LandingPage.js';

function ContactUs() {
  return (
    <View>
      <Navbar/>
      <View style={{margin: 10, alignItems: 'center'}}>
        <Text>Contact our team: <b>admin@parkit-here.com</b></Text>
        <View style={styles.space} />
        <Text>Thank you for using ParkIt!</Text>
      </View>
    </View>
  );
}

export default ContactUs;