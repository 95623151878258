import React from 'react';
import { View, Text, TouchableHighlight, Linking, Image } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { styles } from './App';
import './App.css';

const scheduleTextAPI = process.env.REACT_APP_REQ_PY_URL;

function SuccessBland(props)
{
  var navigate = useNavigate();

  function parkAgain()
  {
    let text_param = (props.send_text === 'true') ? 'y' : 'n';
    const zoneStr = '/get-zone?zone=' + props.zone + '&plate=' + props.plateNumber + '&text=' + text_param;
    navigate(zoneStr);
  }

  return (
    <View style={styles.topLevelContainer}>
      <Text style={styles.heading2Center}>
        You parked {props.plateNumber} in Zone {props.zone}{'\n'}
      </Text>
      <View style={styles.space} />
      <Image 
        style={styles.mainImage} 
        source={process.env.PUBLIC_URL + '/images/ParkIt/10_alt.png'} />
      <View style={styles.space} />
      <View style={styles.textContainer}>
        <View style={styles.space} />
        <Text style={styles.textBold}>
          Thank you for parking with Park It!{'\n'}
        </Text>
      </View>
      <View style={styles.space} />
      <View>
        <TouchableHighlight onPress={parkAgain}>
          <View style={styles.roundedWideButton}> 
          <Text style={styles.buttonText}>Park Again</Text>
          </View>
        </TouchableHighlight>
      </View>
      <Text style={styles.hyperlink}
        onPress={() => Linking.openURL('/privacy-policy')}>
        <u>Privacy Policy</u>
      </Text>
    </View>
  )
}

export default SuccessBland;