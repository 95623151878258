import React from 'react';
import { Text, View, StyleSheet, Image, ActivityIndicator } from 'react-native';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Dimensions } from 'react-native-web';
import logo from './logo.svg';
import './App.css';
import GetZone from './GetZone';
import GetDuration from './GetDuration';
import PaymentConfirmation from './PaymentConfirmation';
import LandingPage from './LandingPage';
import LogIn from './LogIn';
import Analytics from './Analytics';
import ContactUs from './ContactUs';
import PrivacyPolicy from './PrivacyPolicy';
import SuccessBland from './SuccessBland';
import Sales from './Sales'

function AppHeader() {
  return (
    <View style={styles.container}>
          <Text style={styles.titleText}>Welcome to Park App!</Text>
          <Text style={styles.text}>The fastest way to park.</Text>
          <Image style={styles.logo} source={logo}/>
    </View>
  )
}

function APIButton(props) {
  if (props.condition) {
    return (
      <ActivityIndicator size='small' animating={true} color={props.color}/>
    )
  } else {
    return props.defaultText;
  }
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LogIn />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/admin" element={<LandingPage />} />
        <Route path="/get-zone" element={<GetZone />} />
        {/* <Route path="/get-plate" element={<GetPlate />} /> */}
        <Route path="/get-duration" element={<GetDuration />} />
        <Route path="/payment" element={<PaymentConfirmation />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/success" element={<SuccessBland />} />
        <Route path="/sales" element={<Sales/>} />
      </Routes>
    </BrowserRouter>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentScreen: {
    backgroundColor: '#fff',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  app: {
    marginTop: 50
  },
  inputCenter: {
    backgroundColor: '#fff',
    height: 40,
    width: 150,
    padding: 10,
    margin: 10,
    alignItems: 'center', 
    borderWidth: 1,
    borderColor: '#26466F',
  },
  promoInput: {
    backgroundColor: '#fff',
    height: 40,
    width: '70%',
    marginRight: 10,
    padding: 10,
    alignItems: 'left', 
    borderWidth: 1,
    borderColor: '#26466F',
  },
  promoApply: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    width: '30%',
    backgroundColor: '#26466F',
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#26466F', 
    color: '#26466F',
  },
  input: {
    backgroundColor: '#26466F',
    height: 40,
    width: 200,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  inputBorder: {
    borderWidth: 2,
    padding: 10
  },
  logo: {
    width: 66,
    height: 58,
  },
  titleText: {
    color: '#26466F',
    fontSize: 20,
    fontWeight: 'bold'
  },
  buttonText: {
    color: '#8BDEFF',
    fontWeight: 'bold'
  },
  buttonTextDark: {
    color: '#26466F',
    fontWeight: 'bold'
  },
  text: {
    color: '#26466F',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textBold: {
    color: '#26466F',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold'
  },
  lightText: {
    color: '#8BDEFF',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  lightTextSmall: {
    color: '#8BDEFF',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 12,
    fontStyle: 'italic'
  },
  space: {
    width: 10,
    height: 10,
  },
  buttonStyleContainer: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 20,
    marginTop: 5,
  },
  wideButton: {
    height: 40,
    width: 200,
  },
  button: {
    alignItems: "center",
    backgroundColor: '#26466F',
    padding: 10,
    color: '#26466F',
  },
  roundedWideButton: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    backgroundColor: '#26466F',
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#26466F', 
    color: '#26466F',
  },
  roundedWideButtonLight: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    height: 40,
    backgroundColor: '#8BDEFF',
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#26466F', 
    color: '#26466F',
  },
  roundedSmallButton: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    width: 70,
    backgroundColor: '#26466F',
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#26466F', 
    color: '#26466F',
    marginTop: 5,
    margin: 'auto',
    flex: 1,
  },
  dropDown: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    width: Dimensions.get('window').width - 30,
    backgroundColor: '#8BDEFF',
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#26466F', 
    color: '#26466F',
  },
  horizontalGroup: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  horizontalLeft: {
    flex: 1,
    flexDirection: 'row',
  },
  horizontalRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'right',
  },
  promoText: {
    color: '#26466F',
    width: '90%',
    fontStyle: 'italic',
  },
  promoSwitch: {
    justifyContent: 'right',
    alignItems: 'right',
    width: '10%'
  },
  heading: {
    color: '#26466F',
    fontSize: 25,
    fontWeight: 'bold',
  },
  headingCenter: {
    color: '#26466F',
    textAlign: 'center',
    fontSize: 25,
    fontWeight: 'bold',
    marginLeft: 5,
    marginRight: 5,
  },
  heading2Center: {
    color: '#26466F',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: 5,
    marginRight: 5,
  },
  headingLeft: {
    textAlign: 'left',
    alignSelf: 'flex-start',
    color: '#26466F',
    fontSize: 25,
    fontWeight: 'bold',
  },
  headingLight: {
    textAlign: 'center',
    justifyContent: 'center',
    color: '#8BDEFF',
    fontSize: 25,
    fontWeight: 'bold',
  },
  headingText: {
    textAlign: 'center',
    color: '#8BDEFF',
    fontSize: 25,
    fontWeight: 'bold',
  },
  mainImage: {
    aspectRatio: 1,
    resizeMode: 'stretch',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#8BDEFF',
    width: '100%',
    height: '100%',
  },
  phoneNumberEntry: {
    color: '#8BDEFF',
    borderColor: '#8BDEFF',
    borderWidth: 1,
    paddingLeft: 5
  },
  topLevelContainer: {
    flex: 1,
    position: 'relative',
    maxWidth: Dimensions.get('window').width - 10,
    margin: 0,
    paddingTop: 10,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 10,
    resizeMode: 'contain',
  },
  textContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    margin: 20,
    backgroundColor: '#26466F',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    maxWidth: Dimensions.get('window').width - 20,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredHyperlink: {
    flex: 1,
    alignItems: 'center',
  },
  promoDiscount: {
    color: 'red',
  },
  analyticsChart: {
    width: Dimensions.get('window').width / 6,
    height: 200,
    backgroundColor: '#8BDEFF',
    textAlign: 'center',
    justifyContent: 'center',
    color: '#26466F',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#26466F',
  },
  analyticsValue: {
    fontSize: Dimensions.get('window').width / 6 / 5,
    fontWeight: 'bold',
    color: '#26466F',
  },
  analyticsTitle: {
    justifyContent: 'center',
    width: Dimensions.get('window').width / 6,
    color: '#26466F',
    fontWeight: 'bold',
  },
  datePicker: {
    height: 100,
    width: 'auto',
  },
  gridTableStyle: {
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width,
  },
  containerTableStyle: {
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width,
  },
  hyperlink: {
    justifyContent: 'center',
    textAlign: 'center',
    color: 'blue',
    marginTop: 10,
  }
});

export {
  AppHeader,
  styles,
  APIButton
}

export default App;
