import React, { useState, useEffect } from 'react';
import { SalesNavbar } from './LandingPage.js';

function Sales()
{
    return (
        <div>
            <h1>Reach out to Park It at admin@parkit-here.com</h1>
        </div>
    );
}

export default Sales;