import React from 'react';
import { View } from 'react-native';
import './App.css';
import { styles } from './App';
import { Navbar } from './LandingPage.js';

function PrivacyPolicy() {
  return (
    <View>
      <Navbar/>
      <View style={{margin: 10}}>
        By creating an account, signing in, or using ParkIt’s services, you understand and acknowledge our Privacy policies.
        <View style={styles.space} />

        <b><u>Introduction:</u></b>
        ParkIt, LLC is a payment platform that allows users to pay for parking using their phones. “ParkIt,” “we,” and “us” refers to ParkIt, LLC throughout this privacy policy. The purpose of this policy is to share with you what data we collect, how we use that information, and what your rights are to limit the information shared. 

        <View style={styles.space} />
        <b><u>Data Collection and Use:</u></b>
        There is a limited amount of information that ParkIt collects from users. Some of this information is provided directly by the user, and some is automatically collected via other platforms such as Stripe Inc. Data includes:
        <ul>
          <li>Phone number (only if user opts in)</li>
          <li>Email</li>
          <li>Postal code</li>
          <li>State</li>
          <li>License Plate Number</li>
        </ul>
        <View style={styles.space} />
        The information is used for routine product use and may be sold to third parties at ParkIt’s discretion. Routine use of the information includes:
        <ul>
          <li>Phone number: used for text reminders when parking is about to expire if the user opts into this feature</li>
          <li>Email: Used to send receipt</li>
          <li>Postal code: Used to verify payment information</li>
          <li>State: Used to verify payment information</li>
          <li>License Plate Number: Used by parking enforcement officers to ensure proper payment for services</li>
        </ul>
        <View style={styles.space} />
        Note that ParkIt does not collect or store credit card information.
        <View style={styles.space} />

        <b><u>Consumer Rights:</u></b>
        There are a number of privacy laws intended to protect consumers, and ParkIt abides by all applicable laws and regulations. 
        <View style={styles.space} />
        First, ParkIt is not intended for children under 13 years of age. We do not knowingly collect information from children under 13 years old. If you are under 13, please do not provide any information on this website.
        <View style={styles.space} />
        Next, under the California Consumer Privacy Act of 2018 (CCPA), California residents are entitled to know what information is collected about them and how it is used, delete personal information collected about them (with some exceptions), opt-out of the sale or sharing of their personal information, correct inaccurate personal information, and limit the use and disclosure of sensitive personal information collected about them. 
        If any user, from any location, would like to change or restrict the data as covered by applicable laws and regulations, they may send their request to admin@parkit-here.com. ParkIt will work to respond to all requests within 45 calendar days. 
        <View style={styles.space} />

        <b><u>Business Transfers and Related Activities:</u></b>
        ParkIt may sell, merge, buy, or partner with other companies or businesses at any time. In one of these events, personal data may be part of transferred assets.
        <View style={styles.space} />
        <View style={styles.space} />
        <View style={styles.space} />
      </View>
    </View>
  );
}

export default PrivacyPolicy;