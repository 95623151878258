import React from 'react';
import SuccessMessage from './SuccessMessage';
import CanceledMessage from './CanceledMessage';
import SuccessBland from './SuccessBland';
import GetZone from './GetZone';

function PaymentConfirmation() {
  // See if this is a page refresh
  if (window.sessionStorage.length === 0)
  {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let qZone   = urlParams.get('zone');
    let qPlate  = urlParams.get('plate');
    let qText   = urlParams.get('text');
    if (qZone !== null && qPlate !== null)
    {
      return (
        <SuccessBland
          zone={qZone}
          plateNumber={qPlate}
          send_text={qText}
        />
      );
    }
    else
    {
      return (
        <GetZone />
      )
    }
  }

  const increment = parseInt(window.sessionStorage.getItem('increment'));
  const price_per_hour = window.sessionStorage.getItem('price_per_hour');
  const state_code = window.sessionStorage.getItem('state_code');
  const max_time = parseInt(window.sessionStorage.getItem('max_time'));
  const zone = window.sessionStorage.getItem('zone');
  const plateNumber = window.sessionStorage.getItem('plateNumber');
  const subtotal = window.sessionStorage.getItem('subtotal');
  const amount_total = parseInt(window.sessionStorage.getItem('amount_total'));
  const total_details = JSON.parse(window.sessionStorage.getItem('total_details'));
  const duration = JSON.parse(window.sessionStorage.getItem('duration'));
  const checkout_id = window.sessionStorage.getItem('checkout_id');
  const phoneNumber = window.sessionStorage.getItem('phone_number');
  const send_text = window.sessionStorage.getItem('send_text');
  const promo_code = window.sessionStorage.getItem('promo_code');
  const parkit_fee = window.sessionStorage.getItem('parkit_fee');
  const transaction_fee = window.sessionStorage.getItem('transaction_fee');
  const hours = (duration['hours']).toString(); // convert from minutes
  const minutes = duration['minutes'];
  const currDate = new Date();
  var end_time = new Date();
  end_time.setHours(end_time.getHours() + parseInt(duration['hours']));
  end_time.setMinutes(end_time.getMinutes() + parseInt(duration['minutes']));

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const status = urlParams.get('status');

  if (status === 'success') {
    return (
      <SuccessMessage 
        amount_total={amount_total}
        zone={zone}
        plateNumber={plateNumber}
        hours={hours}
        minutes={minutes} 
        increment={increment}
        price_per_hour={price_per_hour}
        state_code={state_code}
        max_time={max_time}
        checkout_id={checkout_id}
        phoneNumber={phoneNumber}
        currDate={currDate}
        send_text={send_text}
        promo_code={promo_code}
        end_time={end_time}
        parkit_fee={parkit_fee}
        transaction_fee={transaction_fee}
      />
    );
  }
  else if (status === 'cancelled') {
    return (
      <CanceledMessage/>
    );
  }
}

  export default PaymentConfirmation;