import React, { useState, useEffect } from 'react';
import { View, Button, Text, ActivityIndicator } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { styles } from './App';
import {
  BarChart,
  Bar,
  Brush,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  FunnelChart,
  LabelList,
  Funnel
} from "recharts";
import { Dimensions } from 'react-native-web';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AdminNavbar } from './LandingPage.js';
import './App.css';

const requestsPyAPI = process.env.REACT_APP_REQ_PY_URL;

function TextFunnel(props)
{
  return (
    <View style={{justifyContent: 'center', alignItems: 'center'}}>
      <Text style={styles.headingCenter}>{props.title}</Text>
      <FunnelChart width={ (Dimensions.get('window').width / 2) } height={500}>
        <Tooltip />
        <Funnel
          dataKey="value"
          data={props.data}
          loading
          margin={{
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <LabelList position="right" fill={props.color} stroke={"none"} dataKey="name" />
        </Funnel>
      </FunnelChart>
    </View>
  );
}

function TextTransactionsFunnel(props)
{
  if (props.loading)
  {
    return (<ActivityIndicator size='large' animating={true} color={props.color}/>);
  }

  const data = [
    {
      "value": props.data[0],
      "name": "Total Sales",
      "fill": props.color
    },
    {
      "value": props.data[1],
      "name": "Text Opt-In",
      "fill": props.secondaryColor
    },
    {
      "value": props.data[2],
      "name": "Text Sales",
      "fill": "#a4de6c"
    }
  ]

  return (
    <TextFunnel title={'Text Transactions'} data={data} color={props.color} secondaryColor={props.secondaryColor}/>
  );
}

function TextRevenueFunnel(props)
{
  if (props.loading)
  {
    return (<ActivityIndicator size='large' animating={true} color={props.color}/>);
  }

  const data = [
    {
      "value": props.data[3] / +100.00,
      "name": "Total Sales",
      "fill": props.color
    },
    {
      "value": props.data[4] / +100.00,
      "name": "Text Opt-In",
      "fill": props.secondaryColor
    },
    {
      "value": props.data[5] / +100.00,
      "name": "Text Sales",
      "fill": "#a4de6c"
    }
  ]

  return (
    <TextFunnel title={'Text Revenue ($)'} data={data} color={props.color} secondaryColor={props.secondaryColor}/>
  );
}

function SummaryTable(props)
{
  function formatLegend(days, loading, color, index)
  {
    var display = '';
    if (index === 0)
    {
      display = 'Transactions Per Day (' + days.toString() + ' Days)';
    }
    else
    {
      display = 'Revenue Per Day (' + days.toString() + ' Days)';
    }
    if (loading)
    {
      return (<ActivityIndicator size='small' animating={true} color={color}/>);
    }
    return display;
  }

  return (
    <BarChart
      width={Dimensions.get('window').width - 50}
      height={500}
      data={props.data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend verticalAlign="top" iconSize={0} formatter={(value, entry, index) => formatLegend(props.days, props.loading, props.color, index)}/>
      <Brush dataKey="name" height={30} stroke="#26466F" />
      <Bar dataKey="transactions" fill="#8BDEFF" />
      <Bar dataKey="revenue" fill="#26466F" />
    </BarChart>
  );
}

function ZoneSummary(props)
{
  function formatLegend(days, loading, color)
  {
    var display = 'Transactions Per Zone (' + days.toString() + ' Days)';
    if (loading)
    {
      return (<ActivityIndicator size='small' animating={true} color={color}/>);
    }
    return display;
  }

  return (
    <BarChart
      width={(Dimensions.get('window').width / 2)}
      height={500}
      data={props.data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="zone" />
      <YAxis />
      <Tooltip />
      <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px", fill: '#8BDEFF' }} iconSize={0} formatter={(value, entry, index) => formatLegend(props.days, props.loading, props.color)}/>
      <Bar fill='#26466F' dataKey="transactions" label={{fill: '#8BDEFF', position: 'insideTop', fontSize: '2rem'}}>
      </Bar>
    </BarChart>
  );
}

function TotalRevenue(props)
{
  var revenue = (props.total / +100.00).toFixed(2);
  var display = '$' + revenue.toString();
  
  return (
    <View>
      <Text style={styles.analyticsTitle}>Revenue ({props.days} Days)</Text>
      <View style={styles.analyticsChart}>
        <Text style={styles.analyticsValue}>{ props.loading ? <ActivityIndicator size='large' animating={true} color={props.loadingColor}/> : display }</Text>
      </View>
    </View>
  );
}

function TotalTransactions(props)
{ 
  return (
    <View>
      <Text style={styles.analyticsTitle}>Transactions ({props.days} Days)</Text>
      <View style={styles.analyticsChart}>
        <Text style={styles.analyticsValue}>{ props.loading ? <ActivityIndicator size='large' animating={true} color={props.loadingColor}/> : props.total }</Text>
      </View>
    </View>
  );
}

function AverageTransaction(props)
{
  var avg_display = (props.avg / +100.00).toFixed(2);
  var display_txt = '$' + avg_display;
  return (
    <View>
      <Text style={styles.analyticsTitle}>Average Transaction Value ({props.days} Days)</Text>
      <View style={styles.analyticsChart}>
        <Text style={styles.analyticsValue}>{ props.loading ? <ActivityIndicator size='large' animating={true} color={props.loadingColor}/> : display_txt }</Text>
      </View>
    </View>
  );
}

function AverageDuration(props)
{
  var duration  = (props.duration / +60.0);
  var hours     = Math.floor(duration).toString().padStart(2, '0');
  var mins      = Math.floor((duration % 1) * 60).toString().padStart(2, '0');
  var display   = hours + ':' + mins;

  return (
    <View>
      <Text style={styles.analyticsTitle}>Average Parking Duration ({props.days} Days)</Text>
      <View style={styles.analyticsChart}>
        <Text style={styles.analyticsValue}>{ props.loading ? <ActivityIndicator size='large' animating={true} color={props.loadingColor}/> : display }</Text>
      </View>
    </View>
  )
}

function Analytics() {
  const [zoneParking, setZoneParking]             = useState([]);
  const [textData, setTextData]                   = useState([]);
  const [avgTransaction, setAvgTransaction]       = useState(0.00);
  const [totalRevenue, setTotalRevenue]           = useState(0.00);
  const [avgDuration, setAvgDuration]             = useState(0.00);
  const [daysPast, setDaysPast]                   = useState(7);
  const [displayDays, setDisplayDays]             = useState('-');
  let customDate                                  = new Date();
  customDate.setDate(customDate.getDate() - daysPast);
  const [searchDate, setSearchDate]               = useState(customDate);
  const [dateUpdated, setDateUpdated]             = useState(0);
  const [csv, setCsv]                             = useState([]);
  const [loading, setLoading]                     = useState(false);
  const [adminZones, setAdminZones]               = useState([]);
  const [lastFetched, setLastFetched]             = useState('');
  const [hasMore, setHasMore]                     = useState(false);
  const [barChartRows, setBarChartRows]           = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0.00);
  const [fetchedData, setFetchedData]             = useState(false);
  const [bufferedData, setBufferedData]           = useState([]);

  function dateDiff(first, second)
  {        
    // Helper to find days between two dates
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  function parseRows(data)
  {
    var transaction_zones   = {};
    var avg_duration        = 0.00;
    var avg_transaction     = 0.00;
    var transactions_dates  = {};
    var daily_revenue       = {};
    var barchart_data       = [];
    var zonechart_data      = [];
    var text_data           = [];
    var csv_data            = [['ID', 'Date (mm/dd/yy)', 'Zone', 'Plate Number', 'State', 'Postal Code', 'Total Paid ($)', 'Stripe Fee ($)', 'Park It Fee ($)', 'Lot Revenue ($)', 'Duration (mins)', 'Email', 'Phone Number', 'Promo Code', 'Sent Text']];
    
    let num_transactions    = 0;
    let num_texts_sent      = 0;
    let num_text_sales      = 0;
    let total_revenue       = 0;
    let text_sent_revenue   = 0;
    let text_revenue        = 0;
    for (var i = 0; i < data.length; i++)
    {
      // First, parse all data
      let session           = data[i];
      let id                = session['id'];
      let zone              = session['zone'];

      if ( !(zone in adminZones) )
      {
        continue;
      }

      num_transactions     += 1;
      num_texts_sent       += parseInt(session['send_text']);
      num_text_sales       += parseInt(session['text_sale']);
      let email             = session['email'];
      let phone_number      = session['phone_number'];
      let state_code        = session['state'];
      let postal_code       = session['postal_code'];
      let duration          = (session['hours']*60) + session['mins'];
      let date_str          = session['date'] + ' UTC';
      let date              = new Date(date_str.replace(" ", "T").replace(" UTC", "Z"));
      date                  = ((date.getMonth() + 1).toString().padStart(2, '0')) + '-' + (date.getDate().toString().padStart(2, '0') + '-' + (date.getYear() - 100).toString());
      let total_paid        = session['total'];
      total_revenue        += parseInt(total_paid);
      let plate_number      = session['plate_number'];
      let transaction_fee   = session['transaction_fee'];
      let parkit_fee        = session['parkit_fee'];
      let promo_code        = session['promo_code'];
      let sent_text         = session['send_text'];
      let stripe_fee        = (total_paid * 0.029) + 0.30;
      let lot_revenue       = total_paid - parkit_fee - stripe_fee;
      let csv_row           = [id, date, zone, plate_number, state_code, postal_code, (total_paid/+100).toFixed(2), (stripe_fee/+100).toFixed(2), (parkit_fee/+100).toFixed(2), (lot_revenue/+100).toFixed(2), duration, email, phone_number, promo_code, sent_text];
      csv_data.push(csv_row);

      // track text sales
      if (parseInt(session['text_sale']) > 0)
      {
        text_revenue       += total_paid;
      }
      if (parseInt(sent_text) > 0)
      {
        text_sent_revenue  += total_paid;
      }
      
      
      // Second, update tracking variables
      if (zone in transaction_zones)
      {
        transaction_zones[zone] += 1;
      }
      else
      {
        transaction_zones[zone] = 1;
      }
      if (date in transactions_dates)
      {
        transactions_dates[date] += 1;
      }
      else
      {
        transactions_dates[date] = 1;
      }
      if (date in daily_revenue)
      {
        daily_revenue[date]['total'] += total_paid;
      }
      else
      {
        if (daily_revenue[date] === undefined)
        {
          daily_revenue[date]           = {};
          daily_revenue[date]['total']  = total_paid;
        }
        else
        {
          daily_revenue[date]['total']  = total_paid;
        }
      }
      if (zone in daily_revenue[date])
      {
        daily_revenue[date][zone] += total_paid;
      }
      else
      {
        daily_revenue[date][zone] = total_paid;
      }

      avg_transaction  += total_paid;
      avg_duration     += duration;
    }
    text_data.push(num_transactions);
    text_data.push(num_texts_sent);
    text_data.push(num_text_sales);
    text_data.push(total_revenue);
    text_data.push(text_sent_revenue);
    text_data.push(text_revenue);

    setTextData(text_data);
    setTotalTransactions(num_transactions);

    avg_duration    = (avg_duration / num_transactions);
    setTotalRevenue(avg_transaction);
    avg_transaction = (avg_transaction / num_transactions);

    // Summarize daily revenue data
    for (var date in daily_revenue)
    {
      let entry = {name: date, revenue: parseFloat( (daily_revenue[date]['total'] / +100.00).toFixed(2) ), transactions: transactions_dates[date]};
      barchart_data.push(entry);
    };

    barchart_data.sort((a, b) => {
      let first     = new Date(a['name'].replace(' ', 'T').replace('-', '/'));
      let second    = new Date(b['name'].replace(' ', 'T').replace('-', '/'));
      
      if (first < second)
      {
        return -1;
      }
      else if (first > second)
      {
        return 11;
      }
      
      return 0;
    });

    setBarChartRows(barchart_data);
    csv_data.sort((a, b) => {
      let day_one = new Date(a[0]);
      let day_two = new Date(b[0]);
      if (day_one < day_two)
      {
        return -1;
      }
      else if (day_one > day_two)
      {
        return 1;
      }
      
      return 0;
    });

    // Summarize Zone parking data
    for (var zone in transaction_zones)
    {
      let entry = {zone: zone, transactions: transaction_zones[zone]};
      zonechart_data.push(entry);
    };

    zonechart_data.sort((a, b) => {
      let num_one = parseInt(a['zone']);
      let num_two = parseInt(b['zone']);

      if (num_one < num_two)
      {
        return -1;
      }
      else if (num_one > num_two)
      {
        return 1;
      }

      return 0;
    });
    
    setCsv(csv_data);
    setAvgTransaction(avg_transaction);
    setAvgDuration(avg_duration);
    setZoneParking(zonechart_data);
  }

  function getAnalytics(time_span, reset)
  {
    setLoading(true);
    let fetch_policy = lastFetched;
    if (reset)
    {
      fetch_policy = '';
    }
    // get list of active parking first
    fetch(requestsPyAPI + '?' + new URLSearchParams( { requestType: 'get_analytics', time_span: time_span, zones: adminZones, last_fetched: fetch_policy } ), {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
    })
    .then((res) => res.json())
    .then((data) => {
      setBufferedData(bufferedData.concat(data['data']));
      setFetchedData(true);
      setLastFetched(data['last_fetched']);
      setHasMore(data['has_more'])
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
      alert('Error, please try again.');
      setFetchedData(false);
      setLastFetched('');
      setHasMore(false);
      setLoading(false);
    })
  }

  function clearAnalytics()
  {
    setBufferedData([]);
    setLastFetched('');
    setHasMore(false);
  }

  function refreshClickHandler()
  {
    if (adminZones.length > 0)
    {
      setDisplayDays(daysPast);
      getAnalytics(daysPast, true);
    }
  }

  function downloadCsv()
  {
    let csvContent  = 'data:text/csv;charset=utf-8,' + csv.map(e => e.join(",")).join("\n");
    let encodedUri  = encodeURI(csvContent);
    let link        = document.createElement('a');
    let csv_name    = 'parkit_data_' + (new Date()).toLocaleDateString() + '.csv';
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', csv_name);
    document.body.appendChild(link);
    link.click();
  }

  function handleDatePickerChange(date)
  {
    setSearchDate(date);
    setDateUpdated(dateUpdated + 1);
  }

  // executes on page enter only
  useEffect(() => {
    let zones = window.sessionStorage.getItem('admin_zones');
    let expiration = window.sessionStorage.getItem('exp');
    if (zones === null || expiration === null)
    {
      // don't have login info, so we need to login
      window.location.assign('/');
    }
    else
    {
      let now = new Date();
      let utc_seconds = expiration;
      let exp_date = new Date(0);
      exp_date.setUTCSeconds(utc_seconds);
      if (exp_date <= now)
      {
        // expired, time to login
        window.location.assign('/');
      }
      else
      {
        if (zones.length > 0)
        {
          zones = zones.split(',');
          setAdminZones(zones);
        }
      }
    }
  }, [])

  // executes on page enter only
  useEffect(() => {
    if (adminZones.length !== 0)
    {
      refreshClickHandler();
    }
  }, [adminZones])

  useEffect(() => {
    if (hasMore && lastFetched !== '' && adminZones)
    {
      getAnalytics(daysPast, false);
    }
    else if (fetchedData && !hasMore && lastFetched !== '' && adminZones)
    {
      parseRows(bufferedData);
      clearAnalytics();
    }
  }, [hasMore, lastFetched, bufferedData])

  useEffect(() => {
    let customDate = new Date();
    let dayInt = parseInt(daysPast);
    if (dayInt > 0)
    {
      customDate.setDate(customDate.getDate() - daysPast);
      setSearchDate(customDate);
    }
    else if (dayInt === 0)
    {
      customDate.setHours(0,0,0,0); // midnight today
    }
  }, [daysPast])

  useEffect(() => {
    // search date has been updated, update the dropdown if possible
    let diff = dateDiff(new Date(), searchDate);
    if (diff < 0)
    {
      let val = document.getElementById('test_id');
      diff = diff * -1;
      val.options[0].selected = true;
      val.options[0].label = diff.toString()
      val.options[0].value = diff;
      setDaysPast(diff);
    }
  }, [dateUpdated, searchDate, adminZones])

  return (
    <View>
      <AdminNavbar/>
      <View style={styles.topLevelContainer} id='top_level'>
        <View style={styles.horizontalRight}>
          <DatePicker style={styles.datePicker} portalId='top_level' selected={searchDate} onChange={(date) => handleDatePickerChange(date)} />
          <View style={styles.space}/>
          <Picker
            selectedValue={daysPast}
            id='test_id'
            onValueChange={(itemValue, itemIndex) =>
              setDaysPast(itemValue)
            }>
            <Picker.Item label='-' value={0} />
            <Picker.Item label='Today' value={0} />
            <Picker.Item label='7 days' value={7} />
            <Picker.Item label='14 days' value={14} />
            <Picker.Item label='21 days' value={21} />
            <Picker.Item label='28 days' value={28} />
            <Picker.Item label='1 month' value={30} />
            <Picker.Item label='1 year' value={365} />
          </Picker>
          <View style={styles.space}/>
          <Button title='Refresh' onPress={refreshClickHandler}/>
          <View style={styles.space}/>
          <Button title='Export' onPress={downloadCsv}/>
        </View>
        <View style={styles.horizontalGroup}>
          <View>
            <View style={styles.horizontalGroup}>
              <AverageTransaction loading={loading} loadingColor={'#26466F'} days={displayDays} avg={avgTransaction}/>
              <View style={styles.space}/>
              <AverageDuration loading={loading} loadingColor={'#26466F'} days={displayDays} duration={avgDuration}/>
            </View>
            <View style={styles.space}/>
            <View style={styles.horizontalGroup}>
              <TotalRevenue loading={loading} loadingColor={'#26466F'} days={displayDays} total={totalRevenue}/>
              <View style={styles.space}/>
              <TotalTransactions loading={loading} loadingColor={'#26466F'} days={displayDays} total={totalTransactions}/>
            </View>
          </View>
          <ZoneSummary loading={loading} loadingColor={'#26466F'} days={displayDays} data={zoneParking}/>
        </View>
        <SummaryTable loading={loading} loadingColor={'#26466F'} days={displayDays} data={barChartRows}/>
        <View style={styles.space} />
        <View style={styles.horizontalGroup}>
          <TextTransactionsFunnel loading={loading} color={'#26466F'} secondaryColor={'#8BDEFF'} data={textData}/>
          <TextRevenueFunnel loading={loading} color={'#26466F'} secondaryColor={'#8BDEFF'} data={textData}/>
        </View>  
      </View>
    </View> 
  );
}

export default Analytics;