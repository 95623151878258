import React, { useState, useEffect } from 'react';
import { View, Text, TouchableHighlight, Linking, Image } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { styles } from './App';
import './App.css';

const scheduleTextAPI = process.env.REACT_APP_REQ_PY_URL;

function SuccessMessage(props)
{
  const navigate = useNavigate();
  
  const [zone, setZone] = useState(props.zone);
  const [plateNumber, setPlateNumber] = useState(props.plateNumber);

  var increment = props.increment;
  var price_per_hour = props.price_per_hour;
  var state_code = props.state_code;
  var max_time = props.max_time;
  var checkout_id = props.checkout_id;
  var phoneNumber = props.phoneNumber;
  var hours = props.hours;
  var minutes = props.minutes;
  var currDate = props.currDate;
  var valid_text = props.send_text;
  var end_time = props.end_time;
  var parkit_fee = props.parkit_fee;
  var transaction_fee = props.transaction_fee;
  var display_time = end_time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  function addTime()
  {
    navigate('/get-duration', { 
      state: {
        'zone': zone,
        'plateNumber': plateNumber,
        'state_code': state_code,
        'price_per_hour': JSON.parse(price_per_hour),
        'increment': increment,
        'max_time': max_time,
        'parkit_fee': parseInt(parkit_fee),
        'transaction_fee': parseInt(transaction_fee)
      } 
    });
  }

  function parkAgain()
  {
    let text_param = (valid_text === 'true') ? 'y' : 'n';
    const zoneStr = '/get-zone?zone=' + zone + '&plate=' + plateNumber + '&text=' + text_param;
    navigate(zoneStr);
  }


  function sendText(phoneNumber, checkout_id, scheduleTime, zone) {  
    const textParams = {
      type: 'schedule_text',
      phone_number: phoneNumber,
      checkout_id: checkout_id,
      schedule_time: scheduleTime,
      zone: zone,
      plate_number: plateNumber,
      domain: window.location.origin,
    }

    fetch(scheduleTextAPI, {
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(textParams),
    })
    .then((res) => res.json())
    .then((data) => {
      // TODO: handle
    })
  }

  function scheduleTextMessage(currDate, hours, minutes)
  {
    // Check if we are ready to schedule the message or not
    var reminder_offset = 15; // offset in minutes

    // send the text 15 minutes before the expiration time
    var scheduleTime = new Date(currDate.getTime() + (hours*60000*60)+(minutes*60000)-(reminder_offset*60000));

    if (window.sessionStorage.getItem(checkout_id) == null)
    {
      // invoke lambda
      if (valid_text === 'true')
      {
        sendText(phoneNumber, checkout_id, scheduleTime, zone);
        window.sessionStorage.setItem(checkout_id, true);
      }
    }
    else
    {
      // we already logged it
      console.log("Already scheduled a text associated with this checkout session");
    }
    
  }

  useEffect(() => {
    if (phoneNumber != null)
    {
      scheduleTextMessage(currDate, hours, minutes);
    }
    else
    {
      console.log("No phone number included")
    }
  });

  return (
    <View style={styles.topLevelContainer}>
      <Text style={styles.headingCenter}>
        Parking expires at {display_time}
      </Text>
      <View style={styles.space} />
      <Image 
        style={styles.mainImage} 
        source={process.env.PUBLIC_URL + '/images/ParkIt/10_alt.png'} />
      <View style={styles.space} />
      <View style={styles.textContainer}>
        <Text style={styles.textBold}>
          You parked {props.plateNumber} in Zone {props.zone} for ${(props.amount_total / 100).toFixed(2)}{'\n'}
          Thank you for parking with Park It!{'\n'}
        </Text>
      </View>
      <View style={styles.space} />
      <View style={styles.horizontalGroup}>
        <TouchableHighlight onPress={addTime}>
          <View style={styles.roundedWideButton}> 
            <Text style={styles.buttonText}>Add Time</Text>
          </View>
        </TouchableHighlight>
        <View style={styles.space} />
        <TouchableHighlight onPress={parkAgain}>
          <View style={styles.roundedWideButton}> 
            <Text style={styles.buttonText}>Park Again</Text>
          </View>
        </TouchableHighlight>
      </View>
      <Text style={styles.hyperlink}
        onPress={() => Linking.openURL('/privacy-policy')}>
        <u>Privacy Policy</u>
      </Text>
    </View>
  )
}

export default SuccessMessage;