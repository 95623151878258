import React, { useState, useEffect } from 'react';
import { styles, APIButton } from './App';
import { View, Modal, Text, Pressable, ActivityIndicator } from 'react-native';
import { TextInput } from 'react-native-web';
import { Buffer } from 'buffer';

const requestsPyAPI = process.env.REACT_APP_REQ_PY_URL;
const cognitoOauthTokenURL = process.env.REACT_APP_COGNITO_OAUTH_URL;
const cognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID;

const Filters = Object.freeze({
  ZONE:   'zone',
  PLATE:  'plate',
  TIME:   'time',
  PRICE:  'price'
});

function AdminNavbar()
{
  return (
    <div className="navbar">
      <a href="/">Home</a>
      <a href="/analytics">Analytics</a>
      <a href="/contact">Contact Us</a>
      <a href="/privacy-policy">Privacy Policy</a>
    </div>
  );
}

function SalesNavbar()
{
  return (
    <div className="navbar">
      <a href="/sales">Sales</a>
      <a href="/contact">Contact Us</a>
      <a href="/privacy-policy">Privacy Policy</a>
      <a href="/terms">Terms of Service</a>
    </div>
  );
}

function Navbar()
{
  return (
    <div className="navbar">
      <a href="/get-zone">Park</a>
      <a href="/contact">Contact Us</a>
      <a href="/privacy-policy">Privacy Policy</a>
    </div>
  );
}

function RowValues(props)
{
  if (props.condition)
  {
    return props.rows;
  }
}

function PageSelect(props)
{
  let options = []
  for (var i = 0; i < parseInt(props.page_max); i++)
  {
    options.push(<option key={i} value={(i+1)}>{(i+1)}</option> );
  }
  return options;
}

function LandingPage() {
  const TableView = {
    ActiveView: 0,
    PastView: 1,
    BootedView: 2
  }

  const [currView, setCurrView]                     = useState(TableView.ActiveView);
  const [rows, setRows]                             = useState([]);
  const [bootedRows, setBootedRows]                 = useState([]);
  const [pastRows, setPastRows]                     = useState([]);
  const [filterStr, setFilterStr]                   = useState("");
  const [page, setPage]                             = useState(1);
  const [tableView, setTableView]                   = useState("Active");
  const [page_max, setPageMax]                      = useState(1);
  const [items_per_page, setItemsPerPage]           = useState(50);
  const [fullData, setFullData]                     = useState([])
  const [timeDict, setTimeDict]                     = useState({});
  const [timeArr, setTimeArr]                       = useState([]);
  const [modalVisible, setModalVisible]             = useState(false);
  const [bootModalVisible, setBootModalVisible]     = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [clickedRow, setClickedRow]                 = useState([])
  const [apiLoading, setApiLoading]                 = useState(false);
  const [bootZone, setBootZone]                     = useState(0);
  const [bootPlateNumber, setBootPlateNumber]       = useState('');
  const [zoneFilter, setZoneFilter]                 = useState('');
  const [logInInfo, setLogInInfo]                   = useState({});
  const [adminEmail, setAdminEmail]                 = useState('');
  const [adminUsername, setAdminUsername]           = useState('');
  const [adminData, setAdminData]                   = useState('');
  const [adminZones, setAdminZones]                 = useState([]);
  const [zoneList, setZoneList]                     = useState([]);
  const [zoneString, setZoneString]                 = useState('Zone Filter');
  const [fetchedData, setFetchedData]               = useState(false);
  const [bufferedData, setBufferedData]             = useState([]);
  const [lastFetched, setLastFetched]               = useState('');
  const [hasMore, setHasMore]                       = useState(false);
  const [pastFetchedData, setPastFetchedData]       = useState(false);
  const [pastBufferedData, setPastBufferedData]     = useState([]);
  const [pastLastFetched, setPastLastFetched]       = useState('');
  const [pastHasMore, setPastHasMore]               = useState(false);
  const [filterType, setFilterType]                 = useState(Filters.TIME);
  const [deleteVehicleUUID, setDeleteVehicleUUID]   = useState('');
  const [deletePlate, setDeletePlate]               = useState('');
  const [deleteDate, setDeleteDate]                 = useState('');

  var tmpTimeDict = {};
  var tmpTimeArr = [];
  var tmpPastRowsId = [];
  var orderArr = [];

  var idx_l = (page-1)*items_per_page;
  var idx_r = Math.min(idx_l + items_per_page, rows.length);

  function parseRows(data)
  {
    let zone_list = new Set()
    var today = new Date().getTime()
    today = new Date(today)
    for (var i = 0; i < data.length; i++)
    {
      var uuid = data[i]['uuid'];
      var date = data[i]['date'];
      var zone = data[i]['zone'];

      if ( !(zone in adminZones) )
      {
        // Only show this admin their own zones
        continue;
      }

      zone_list.add(zone);
      var endDate = new Date(date);
      endDate.setHours( endDate.getHours() + parseInt(data[i]['hours']) );
      endDate.setMinutes( endDate.getMinutes() + parseInt(data[i]['mins']) );
      endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());

      // now get the time remaining
      var timeRemaining = endDate - today;
      var hour_past = -(60000 * 60);
      var dollars = parseInt(data[i]['total'] / 100);
      var cents = data[i]['total'] % 100;
      var total_dollars = "$" + dollars + "." + cents.toString().padStart(2, '0');
      var keyStr = uuid
      if (timeRemaining > hour_past)
      {
        var diff = new Date(timeRemaining).toISOString().slice(11, 16);
        var diff_days = parseInt( new Date(timeRemaining).toISOString().slice(8, 10) ) - 1;

        // handle multiple days remaining
        if (diff_days > 0)
        {
          var curr_hours = parseInt(diff.slice(0, 2));
          var curr_mins  = diff.slice(3, 5);
          curr_hours += (diff_days * 24); // add days to total hours
          diff = curr_hours.toString().padStart(2, '0') + ':' + curr_mins;
        }
        if (timeRemaining < 0)
        {
          let expired_mins = Math.floor( ((timeRemaining * -1) / 60000) % 60 );
          let expired_hours = Math.floor( ((timeRemaining * -1) / 60000) / 60 );
          diff = 'Expired (' + expired_hours.toString().padStart(2, '0') + ':' + expired_mins.toString().padStart(2, '0') + ')';
        }
        var plateId = data[i]['plateNum'] + '-' + data[i]['zone'];
        var idx = 0;
        if (tmpTimeDict[plateId] === undefined)
        {
          // ensures we have a unique uuid
          idx = tmpTimeArr.push([keyStr, zone, data[i]['plateNum'], diff, total_dollars, uuid, date, (timeRemaining).toString()]);
          tmpTimeDict[plateId] = [idx - 1, [uuid]];
          orderArr.push(plateId);
        }
        else
        {
          if (tmpTimeDict[plateId][1].includes(uuid))
          {
            // same uuid means we have the same transaction, so we can skip
            continue;
          }

          // calculate which is longer and sum the prices
          tmpTimeDict[plateId][1].push(uuid);
          idx = tmpTimeDict[plateId][0];
          var previous = tmpTimeArr[idx];
          var prev_diff = previous[3];
          var prev_price = previous[4];
          var prev_neg_val = parseInt(previous[7]);
          var prev_negative = prev_neg_val < 0;
          var tmp_price = 0;

          // The cases below figure out how to display the price and duration
          if ( (prev_negative && (timeRemaining > 0)))
          {
            // Case 1: previous entry is negative and current entry is positive --> display current
            tmp_price = parseFloat(total_dollars.slice(1)) + parseFloat(prev_price.slice(1));
            tmpTimeArr[idx][4] = "$" + tmp_price.toFixed(2);
            tmpTimeArr[idx][3] = diff;
          }
          else if (prev_negative && (timeRemaining < 0))
          {
            // Case 2: previous entry and current entry are negative
            tmp_price = parseFloat(total_dollars.slice(1)) + parseFloat(prev_price.slice(1));
            tmpTimeArr[idx][4] = "$" + tmp_price.toFixed(2);
            if (prev_neg_val < timeRemaining)
            {
              // Case 2: previous entry and current entry are negative, current entry is more recent --> display current
              tmpTimeArr[idx][3] = diff;
            }
            // Case 2: previous entry and current entry are negative, previous entry is more recent --> do nothing
          }
          else if (!prev_negative && (timeRemaining < 0))
          {
            // case 3: previous entry is positive and current entry is negative --> display previous
            tmp_price = parseFloat(total_dollars.slice(1)) + parseFloat(prev_price.slice(1));
            tmpTimeArr[idx][4] = "$" + tmp_price.toFixed(2);
          }
          else if (!prev_negative && (timeRemaining > 0))
          {
            // case 4: previous entry is positive and current entry is positive --> sum the amounts and durations
            tmp_price = parseFloat(total_dollars.slice(1)) + parseFloat(prev_price.slice(1));
            tmpTimeArr[idx][4] = "$" + tmp_price.toFixed(2);
        
            let prev_time = prev_diff.split(':');
            let prev_hours = parseInt(prev_time[0]);
            let prev_mins = parseInt(prev_time[1]);
            let prev_mins_total = (prev_hours * 60) + prev_mins;

            let curr_time = diff.split(':');
            let curr_hours = parseInt(curr_time[0]);
            let curr_mins = parseInt(curr_time[1]);
            let curr_mins_total = (curr_hours * 60) + curr_mins;

            let total_mins = prev_mins_total + curr_mins_total;

            let mins = Math.floor( total_mins % 60 );
            let hours = Math.floor( total_mins / 60 );
            diff = hours.toString().padStart(2, '0') + ':' + mins.toString().padStart(2, '0');
            tmpTimeArr[idx][3] = diff;
          }
          else
          {
            // know we should display the new entry
            tmp_price = parseFloat(total_dollars.slice(1)) + parseFloat(prev_price.slice(1));
            tmpTimeArr[idx] = [keyStr, zone, data[i]['plateNum'], diff, "$" + tmp_price.toFixed(2), uuid, date, (timeRemaining > 0).toString()];
          }
        }
      }
    }
    
    setZoneList(zone_list);
    setTimeDict(tmpTimeDict);
    setFullData(orderArr);
    setTimeArr(tmpTimeArr);

    var elements = document.getElementsByClassName('time_cell');
    for (i = 0; i < elements.length; i++)
    {
      // Set expired times to be red
      if (elements[i].innerHTML.startsWith('Expired'))
      {
        elements[i].style.color = 'red';
      }
      else
      {
        elements[i].style.color = 'black';
      }
    }
  }

  function parseBootedRows(data)
  {
    var tmpBootedRows = [];
    let zone_list = new Set(zoneList);
    for (var i = 0; i < data.length; i++)
    {
      var uuid = data[i]['uuid']
      var date = data[i]['date']
      var plate_num = data[i]['plate_num']
      var zone = data[i]['zone']
      if ( !(zone in zone_list) )
      {
        zone_list.add(zone);
      }
      var payment_status = data[i]['payment_status']

      tmpBootedRows.push([uuid, date, plate_num, zone, payment_status])
    }
    setZoneList(zone_list);
    setBootedRows(tmpBootedRows);
    document.getElementById('button_right').style.display = 'block';
    document.getElementById('activity_indicator_id').style.display = 'none';
  }

  function parsePastRows(data)
  {
    var tmpPastRows = [];
    let zone_list = new Set(zoneList);
    for (var i = 0; i < data.length; i++)
    {
      var uuid = data[i]['uuid']
      if (tmpPastRowsId.includes(uuid))
      {
        // have already counted this item
        continue;
      }
      tmpPastRowsId.push(uuid);
      var startDate = new Date(data[i]['date'])
      var endDate = new Date(data[i]['date']);
      var today = new Date().getTime();
      endDate.setHours( endDate.getHours() + parseInt(data[i]['hours']) );
      endDate.setMinutes( endDate.getMinutes() + parseInt(data[i]['mins']) );
      startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
      endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());

      // now get the time remaining
      var timeRemaining = endDate - today;
      var dollars = parseInt(data[i]['total'] / 100);
      var cents = data[i]['total'] % 100;
      var total_dollars = "$" + dollars + "." + cents.toString().padStart(2, '0');
      var plate_num = data[i]['plateNum'];
      var zone = data[i]['zone'];
      var diff = new Date(timeRemaining).toISOString().slice(11, 16);

      if (timeRemaining < 0)
        {
          // Special case, time is expired
          timeRemaining *= -1;
          let expired_mins = Math.floor( ((timeRemaining) / 60000) % 60 );
          let expired_hours = Math.floor( ((timeRemaining) / 60000) / 60 );
          diff = 'Expired (' + expired_hours.toString().padStart(2, '0') + ':' + expired_mins.toString().padStart(2, '0') + ')';
        }

      if ( !(zone in zone_list) )
      {
        zone_list.add(zone);
      }

      tmpPastRows.push([uuid, diff, zone, plate_num, total_dollars])
    }
    setZoneList(zone_list);
    setPastRows(tmpPastRows);
  }

  function getUserInfo(auth_code, oauth_token_url, client_id, redirect_url)
  {
    // Get User Information for logged in user
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "authorization_code");
    urlencoded.append("client_id", client_id);
    urlencoded.append("code", auth_code);
    urlencoded.append("redirect_uri", redirect_url);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(oauth_token_url, requestOptions)
      .then(response => response.text())
      .then(result => {
        var data = JSON.parse(result);
        if (data['error'])
        {
          console.log(data['error'])
          if (data['error'] === 'invalid_grant')
          {
            // need to re login
            window.location.assign('/');
          }
        }
        else
        {
          let idToken = data['id_token'];
          let tokens = idToken.split(".");
          let info = JSON.parse(Buffer.from(tokens[1], 'base64'));
          setLogInInfo(info);
          window.sessionStorage.setItem('exp', info['exp']);
          setAdminEmail(info['email']);
          setAdminUsername(info['cognito:username']);
        }
      })
      .catch(error => console.log('error', error));
  }

  function clearRefreshStatus()
  {
    document.getElementById('button_right').style.display = 'block';
    document.getElementById('activity_indicator_id').style.display = 'none';
    setPastFetchedData(false);
    setPastLastFetched('');
    setPastHasMore(false);
    setPastBufferedData([]);

    setFetchedData(false);
    setLastFetched('');
    setBufferedData([]);
  }

  function getPastTransactions(zones, reset)
  {
    let fetch_policy = pastLastFetched;
    if (reset)
    {
      fetch_policy = '';
    }
    // get list of active parking first
    fetch(requestsPyAPI + '?' + new URLSearchParams( { requestType: 'get_past_transactions', zones: zones, last_fetched: fetch_policy } ), {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
    })
    .then((res) => res.json())
    .then((data) => {
      setPastBufferedData(pastBufferedData.concat(data['data']));
      setPastFetchedData(true);
      setPastLastFetched(data['last_fetched']);
      setPastHasMore(data['has_more'])
    })
    .catch((e) => {
      console.log(e);
      alert('Unknown error, please try again.');
      clearRefreshStatus();
    })
  }

  function getTransactions(zones, reset)
  {
    let fetch_policy = lastFetched;
    if (reset)
    {
      fetch_policy = '';
    }

    if (currView === TableView.ActiveView)
    {
      fetch(requestsPyAPI + '?' + new URLSearchParams( { requestType: 'get_transactions', zones: zones, last_fetched: fetch_policy } ), {
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
      })
      .then((res) => res.json())
      .then((data) => {
        if (data === '-1')
        {
          // error occurred
          clearRefreshStatus();
        }
        else
        {
          setBufferedData(bufferedData.concat(data['data']));
          setFetchedData(true);
          setLastFetched(data['last_fetched']);
          setHasMore(data['has_more']);
        }
      })
      .catch((e) => {
        console.log(e);
        alert('Unknown error, please try again.');
        setFetchedData(false);
        setLastFetched('');
        setHasMore(false);
      })
    }
    else if (currView === TableView.BootedView)
    {
      // TODO: only get last 2 days
      fetch(requestsPyAPI + '?' + new URLSearchParams( { requestType: 'get_booted', zones: zones } ), {
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
      })
      .then((res) => res.json())
      .then((data) => {
        parseBootedRows(data)
      })
      .catch((e) => {
        console.log(e);
      })
    }
    else if (currView === TableView.PastView)
    {
      // now get the past transactions
      getPastTransactions(zones, reset);
    }
  }

  function getAdminZones(email)
  {
    // get list of active parking first
    fetch(requestsPyAPI + '?' + new URLSearchParams( { requestType: 'get_admins', email: email } ), {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
    })
    .then((res) => res.json())
    .then((data) => {
      setAdminData(data);
    })
    .catch((e) => {
      console.log(e);
      clearRefreshStatus();
    })
  }

  function onTableHeaderClick(event)
  {
    let header = event.target.innerText; 
    switch (header)
    {
      case 'Zone':        
        setFilterType(Filters.ZONE);
        break;
      case 'Plate':
        setFilterType(Filters.PLATE);
        break;
      case 'Time Remaining':
        setFilterType(Filters.TIME);
        break;
      case 'Price':
        setFilterType(Filters.PRICE);
        break;
    }
  }

  useEffect(() => {
    // now that admin data has been updated, we can get transactions
    if (adminData['email'] === adminEmail && adminZones.length === 0)
    {
      setAdminZones(JSON.parse(adminData['zones']));
    }
    else if (adminData === '')
    {
      // if we are here, it's the initial refresh, so we need to get data
      refresh_click_handler();
    }
  }, [adminData, adminEmail])

  useEffect(() => {
    var elements = document.getElementsByClassName('time_cell');
    for (var i = 0; i < elements.length; i++)
    {
      // Set expired times to be red
      if (elements[i].innerHTML.startsWith('Expired'))
      {
        elements[i].style.color = 'red';
      }
      else
      {
        elements[i].style.color = 'black';
      }
    }
  }, [rows, pastRows])

  useEffect(() => {
    // Collects active view transactions
    if (hasMore && lastFetched !== '' && adminZones)
    {
      document.getElementById('button_right').style.display = 'none';
      document.getElementById('activity_indicator_id').style.display = 'block';
      getTransactions(adminZones, false);
    }
    else if (fetchedData && !hasMore && lastFetched !== '' && adminZones)
    {
      parseRows(bufferedData)
      clearRefreshStatus();
    }
  }, [hasMore, lastFetched, bufferedData])

  useEffect(() => {
    // Collects past view transactions
    if (pastHasMore && pastLastFetched !== '' && adminZones)
    {
      getPastTransactions(adminZones, false);
    }
    else if (pastFetchedData && !pastHasMore && pastLastFetched !== '' && adminZones)
    {
      parsePastRows(pastBufferedData);
      clearRefreshStatus();
    }
  }, [pastHasMore, pastLastFetched, pastBufferedData])

  useEffect(() => {
    var select = document.getElementById('zone_type');
    let zones = Array.from(zoneList);

    zones.sort((a, b) => {
      let a_val = parseInt(a);
      let b_val = parseInt(b);

      if (a_val < b_val)
      {
        return -1;
      }
      else if (a_val === b_val)
      {
        return 0;
      }
      else
      {
        return 1;
      }
    });

    select.innerHTML = '';
    var option = document.createElement('option');
    option.value = option.text = 'Zone Filter';
    select.add(option);
    setZoneString(option.text);

    for (var i = 0; i < zones.length; i++)
    {
      option = document.createElement('option');
      option.value = option.text = zones[i];
      select.add(option);
    }
  }, [zoneList])

  useEffect(() => {
    // now that zones have been updated, we can get new data
    if (adminZones.length !== 0)
    {
      window.sessionStorage.setItem('admin_zones', adminZones);
      getTransactions(JSON.stringify(adminZones), false);
    }
  }, [adminZones])

  function clearTransactions()
  {
    tmpPastRowsId = [];
  }

  function refresh_click_handler()
  {
    if (adminEmail !== '' && adminZones.length === 0)
    {
      getAdminZones(adminEmail);
      document.getElementById('button_right').style.display = 'none';
      document.getElementById('activity_indicator_id').style.display = 'block';
    }
    else if (adminZones.length !== 0)
    {
      clearTransactions();
      getTransactions(adminZones, true);
      document.getElementById('button_right').style.display = 'none';
      document.getElementById('activity_indicator_id').style.display = 'block';
    }
  }

  function handleBootDelete(uuid, date, license_num)
  {
    setDeleteVehicleUUID(uuid);
    setDeletePlate(license_num);
    setDeleteDate(date);
    setDeleteModalVisible(true);
  }

  function row_click_handler(props)
  {
    setClickedRow(props) // have all the properties we need
    if (props.visible)
    {
      setModalVisible(false);
    }
    else
    {
      setModalVisible(true);
    }
  }

  function add_boot_handler()
  {
    setBootModalVisible(true);
  }

  function orderData(data, type)
  {
    // order everything
    if (type === Filters.TIME)
    {
      orderByDuration(data);
    }
    else if (type === Filters.PLATE)
    {
      orderByPlate(data);
    }
    else if (type === Filters.ZONE)
    {
      orderByZone(data);
    }
    else if (type === Filters.PRICE)
    {
      orderByPrice(data);
    }
    setRows(data);
  }

  function orderByDuration(data)
  {
    data.sort((a,b) => {
      var a_val = a['props']['children'][2]['props']['children'];
      var b_val = b['props']['children'][2]['props']['children'];
      if (a_val.startsWith('Expired') && !b_val.startsWith('Expired'))
      {
        if (currView === TableView.ActiveView)
        {
          // Show expired first on active view, but last on Past view since it's expected
          return -1;
        }
        else
        {
          return 1;
        }
      }
      else if (a_val.startsWith('Expired') && b_val.startsWith('Expired'))
      {
        a_val = a_val.replace('Expired ', '');
        b_val = b_val.replace('Expired ', '');
        a_val = a_val.replace(':', '.');
        b_val = b_val.replace(':', '.');
        a_val = a_val.replace('(', '');
        a_val = a_val.replace(')', '');
        b_val = b_val.replace('(', '');
        b_val = b_val.replace(')', '');
        a_val = parseFloat(a_val);
        b_val = parseFloat(b_val);
        if (currView === TableView.ActiveView)
        {
          return (a_val > b_val) ? -1: 1; // show 'more' expired items first
        }
        else
        {
          return (a_val < b_val) ? -1: 1; // show 'less' expired items first
        }
      }
      else if (a_val === b_val)
      {
        return 0;
      }
      else
      {
        a_val = a_val.replace(':', '.');
        b_val = b_val.replace(':', '.');
        a_val = parseFloat(a_val);
        b_val = parseFloat(b_val);
        if (a_val < b_val)
        {
          return -1;
        }
        else if (b_val > a_val)
        {
          return 1;
        }
        else
        {
          return 0;
        }
      }
    });
  }

  function orderByPlate(data)
  {
    data.sort((a,b) => {
      var a_val = a['props']['children'][1]['props']['children'];
      var b_val = b['props']['children'][1]['props']['children'];
      return a_val.localeCompare(b_val);
    });
  }

  function orderByZone(data)
  {
    data.sort((a,b) => {
      var a_val = parseInt(a['props']['children'][0]['props']['children']);
      var b_val = parseInt(b['props']['children'][0]['props']['children']);
      return (a_val < b_val) ? -1 : 1;
    });
  }

  function orderByPrice(data)
  {
    data.sort((a,b) => {
      var a_val = parseInt(a['props']['children'][3]['props']['children'].replace('$', '').replace('.', ''));
      var b_val = parseInt(b['props']['children'][3]['props']['children'].replace('$', '').replace('.', ''));
      return (a_val < b_val) ? -1 : 1;
    });
  }

  function add_booted_vehicle(vehicle)
  {
    setApiLoading(true);

    const vehicle_params = {
      type: 'boot_vehicle',
      zone: vehicle[1],
      plate_num: vehicle[2],
      time_remaining: vehicle[3],
      total_paid: vehicle[4],
      uuid: vehicle[5],
      date: vehicle[6],
      payment_status: "Past Due",
    }
  
    fetch(requestsPyAPI, {
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(vehicle_params),
    })
    .then((data) => {
      if (data['status'] !== 200)
      {
        console.log("Error occured in booting!")
      }
      setApiLoading(false);
    })
    .catch((e) => {
      console.log(e);
      alert('Timeout when fetching data from server, please try again.');
    })

    setApiLoading(false)
    if (modalVisible)
    {
      setModalVisible(!modalVisible)
    }
    else if (bootModalVisible)
    {
      setBootModalVisible(!bootModalVisible)
    }
  }

  function remove_boot(row_uuid, row_date, row_plate)
  {
    setApiLoading(true);

    const vehicle_params = {
      type: 'remove_boot',
      uuid: row_uuid,
      date: row_date,
      license: row_plate,
    }
  
    fetch(requestsPyAPI, {
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(vehicle_params),
    })
    .then((data) => {
      if (data['status'] !== 200)
      {
        console.log("Error occured in booting!")
      }
    })
    .catch((e) => {
      console.log(e);
      alert('Timeout when fetching data from server, please try again.');
    })

    setApiLoading(false)
    if (deleteModalVisible)
    {
      setDeleteModalVisible(!deleteModalVisible);
    }
  }

  // TODO: split this into functions
  function activeFilter(tmp_filter)
  {
    var tmpOrderArr = [];
    var rows_filtered = [];
    var i;
    var zoneInt = parseInt(zoneFilter)
    if (currView === TableView.ActiveView)
    {
      tmpOrderArr = fullData;
      
      tmpOrderArr.sort((a, b) => {
        return a.localeCompare(b);
      });
      for (i = 0; i < tmpOrderArr.length; i++)
      {
        var timeVal = timeDict[tmpOrderArr[i]][0]
        let currArr = timeArr[timeVal]
        if ( currArr.slice(1,).filter(str => str.toLowerCase().includes(tmp_filter.toLowerCase())).length )
        {
          if ( isNaN(zoneInt) || (parseInt(currArr[1]) === zoneInt) )
          {
            rows_filtered.push(<tr key={currArr[0]} onClick={() => row_click_handler(currArr)}><td>{currArr[1]}</td><td className="plate_cell">{currArr[2]}</td><td className='time_cell'>{currArr[3]}</td><td>{currArr[4]}</td></tr>)
          }
        }
      }
    }
    else if (currView === TableView.PastView)
    {
      tmpOrderArr = pastRows;
      
      tmpOrderArr.sort((a, b) => {
        // sort by which is 'more' expired
        let a_val = a[1];
        let b_val = b[1];
        if (a_val.startsWith('Expired') && !b_val.startsWith('Expired'))
        {
          // Show expireds last in past view table
          return 1;
        }
        else if (!a_val.startsWith('Expired') && b_val.startsWith('Expired'))
        {
          return -1;
        }

        // clean up the time remaining string to order it
        a_val.replace('Expired (', '');
        b_val.replace('Expired (', '');
        a_val.replace(')', '');
        b_val.replace(')', '');
        a_val = a_val.split(':');
        b_val = b_val.split(':');
        a_val = (parseInt(a_val[0]) * 60) + parseInt(a_val[1]);
        b_val = (parseInt(b_val[0]) * 60) + parseInt(b_val[1]);

        
        if (a_val > b_val)
        {
          return -1;
        }
        else if (a_val === b_val)
        {
          return 0;
        }
        else
        {
          return 1;
        }
      });

      var keyStr = '';
      for (i = 0; i < tmpOrderArr.length; i++)
      {
        keyStr = 'tableRowPast' + i;
        if ( tmpOrderArr[i].slice(2,4).filter(str => str.toLowerCase().includes(tmp_filter.toLowerCase())).length )
        {
          if ( isNaN(zoneInt) || (parseInt(tmpOrderArr[i][2]) === zoneInt) )
          {
            rows_filtered.push(<tr key={keyStr}><td className="zoneCell">{tmpOrderArr[i][2]}</td><td  className="plateCell">{tmpOrderArr[i][3]}</td><td className='time_cell'>{tmpOrderArr[i][1]}</td><td>{tmpOrderArr[i][4]}</td></tr>)
          }
        }
      }
    }
    else if (currView === TableView.BootedView)
    {
      tmpOrderArr = bootedRows;
      
      tmpOrderArr.sort((a, b) => {
        return a[2].localeCompare(b[2]);
      });

      keyStr = '';
      for (i = 0; i < tmpOrderArr.length; i++)
      {
        keyStr = 'tableRowBooted' + i;
        if ( tmpOrderArr[i].slice(2,4).filter(str => str.toLowerCase().includes(tmp_filter.toLowerCase())).length )
        {
          // TODO: onclick, option to delete
          if ( isNaN(zoneInt) || (parseInt(tmpOrderArr[i][3]) === zoneInt) )
          {
            let uuid = tmpOrderArr[i][0];
            let date = tmpOrderArr[i][1];
            let license_num = tmpOrderArr[i][2];
            rows_filtered.push(<tr key={keyStr} onClick={() => handleBootDelete(uuid, date, license_num)}><td>{tmpOrderArr[i][3]}</td><td>{tmpOrderArr[i][2]}</td><td className="plateCell">-</td><td>-</td></tr>)
          }
        }
      }
    }
    
    orderData(rows_filtered, filterType);
  }

  useEffect(() => { 
    // get params
    let url_params = new URLSearchParams(window.location.search);
    let authCode = url_params.get('code');
    window.sessionStorage.setItem('auth_code', authCode);
    let redirect = window.location.origin + '/admin';
    getUserInfo(authCode, cognitoOauthTokenURL, cognitoClientId, redirect);
  }, [])

  useEffect(() => {
    if (items_per_page === undefined || rows.length === undefined || rows.length === 0)
    {
      setItemsPerPage(25);
      setPageMax(1);
      setPage(1);
    }
    else
    {
      var num_pages = Math.ceil((rows.length / items_per_page));
      setItemsPerPage(parseInt(items_per_page));
      setPageMax(num_pages);
      if (parseInt(items_per_page) >= rows.length)
      {
        setPage(1);
      }
    }
  }, [items_per_page, page_max, page, rows.length])

  useEffect(() => {
    if (tableView === "Active")
    {
      setCurrView(TableView.ActiveView);
      document.getElementById("left").style.width = '30%';
      document.getElementById("zone_type").style.display = 'block';
      document.getElementById("add-booted-button").style.display = 'none';
    }
    else if (tableView === "Past")
    {
      setCurrView(TableView.PastView);
      document.getElementById("left").style.width = '30%';
      document.getElementById("zone_type").style.display = 'block';
      document.getElementById("add-booted-button").style.display = 'none';

      document.getElementById('button_right').style.display = 'none';
      document.getElementById('activity_indicator_id').style.display = 'block';
      getPastTransactions(adminZones, true);
    }
    else if (tableView === "Booted")
    {
      setCurrView(TableView.BootedView);
      document.getElementById("left").style.width = '50%';
      document.getElementById("zone_type").style.display = 'none';
      document.getElementById("add-booted-button").style.display = 'block';
    }
  }, [tableView, TableView.ActiveView, TableView.PastView, TableView.BootedView])

  useEffect(() => {
    // handles the filter input box
    let tmp_filter = filterStr;
    activeFilter(tmp_filter);
  }, [filterType, filterStr, fullData, timeDict, timeArr,
      TableView.ActiveView, TableView.PastView, TableView.BootedView,
      bootedRows, pastRows, currView, idx_l, idx_r]);

  useEffect(() => {
    // Handles the zone filter dropdown only
    let tmp_filter = zoneFilter;
    if (zoneFilter === 'Zone Filter')
    {
      tmp_filter = '';
    }
    activeFilter(tmp_filter);
  }, [zoneFilter]);

  useEffect(() => {
    // show loading until we have multiple rows
    let num_rows = document.getElementById('table_body').rows.length;
    if ( num_rows !== 0 && !hasMore && lastFetched !== '' )
    {
      clearRefreshStatus();
    }
    else if ( !hasMore && lastFetched === '' && fetchedData )
    {
      clearRefreshStatus();
    }
  }, [rows, fetchedData, hasMore, lastFetched]);

  return (
    <div>
      <div>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          style={styles.modalView}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
        }}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.lightText}>Boot vehicle with license {clickedRow[2]}?</Text>
              <Text style={styles.lightText}>Vehicle has {clickedRow[3]} remaining to park.</Text>
              <View style={styles.space} />
              <View style={styles.horizontalGroup}>
                <Pressable
                  style={styles.roundedWideButtonLight}
                  onPress={() => add_booted_vehicle(clickedRow)}>
                  <APIButton condition={apiLoading} defaultText={'Boot Vehicle'} color={'#26466F'}/>
                </Pressable>
                <View style={styles.space} />
                <Pressable
                  style={styles.roundedWideButtonLight}
                  onPress={() => setModalVisible(!modalVisible)}>
                  <APIButton defaultText={'Back'} color={'#26466F'}/>
                </Pressable>
              </View>
            </View>
          </View>
        </Modal>

        <Modal
          animationType="slide"
          transparent={true}
          visible={deleteModalVisible}
          style={styles.modalView}
          onRequestClose={() => {
            setDeleteModalVisible(!deleteModalVisible);
        }}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.lightText}>Remove boot from vehicle with license {deletePlate}?</Text>
              <View style={styles.space} />
              <View style={styles.horizontalGroup}>
                <Pressable
                  style={styles.roundedWideButtonLight}
                  onPress={() => remove_boot(deleteVehicleUUID, deleteDate, deletePlate)}>
                  <APIButton condition={apiLoading} defaultText={'Remove Boot'} color={'#26466F'}/>
                </Pressable>
                <View style={styles.space} />
                <Pressable
                  style={styles.roundedWideButtonLight}
                  onPress={() => setDeleteModalVisible(!deleteModalVisible)}>
                  <APIButton defaultText={'Back'} color={'#26466F'}/>
                </Pressable>
              </View>
            </View>
          </View>
        </Modal>

        <Modal
          animationType="slide"
          transparent={true}
          visible={bootModalVisible}
          style={styles.modalView}
          onRequestClose={() => {
            setBootModalVisible(!bootModalVisible);
        }}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.lightText}>Add a vehicle to be booted</Text>
              <View style={styles.space} />
              {/* Add a boot vehicle */}
              <View style={styles.horizontalGroup}>
                <TextInput
                  style={styles.inputCenter}
                  onChangeText={setBootZone}
                  placeholder='Zone Number'
                  keyboardType='numeric'
                />
                <TextInput
                  style={styles.inputCenter}
                  onChangeText={setBootPlateNumber}
                  placeholder='Plate Number'
                  keyboardType='default'
                />
              </View>
              <View style={styles.horizontalGroup}>
                <Pressable
                  style={styles.roundedWideButtonLight}
                  onPress={() => add_booted_vehicle([-1, bootZone, bootPlateNumber, 0, 0, -1, -1])}>
                  <APIButton defaultText={'Submit'} color={'#26466F'}/>
                </Pressable>
                <View style={styles.space}/>
                <Pressable
                  style={styles.roundedWideButtonLight}
                  onPress={() => setBootModalVisible(!bootModalVisible)}>
                  <APIButton defaultText={'Back'} color={'#26466F'}/>
                </Pressable>
              </View>
            </View>
          </View>
        </Modal>

        <AdminNavbar/>

        <div className="table-wrapper"> 
          <div className="top-row">
            <div id="left">
              <input
                    type="text"
                    value={filterStr}
                    placeholder="Filter"
                    onChange={(e) => setFilterStr(e.target.value)} />
              <button className="hidden-button" id="add-booted-button" onClick={add_boot_handler}>Add Vehicle</button>
            </div>
            <div id="right">
              <select defaultValue={"zone_choice"} id="zone_type" onChange={(e) => setZoneFilter(e.target.value)}>
                <option value="zone_choice" id="zone_choice">{zoneString}</option>
              </select>
              <select defaultValue={"Active"} name="table_type" id="table_type" onChange={(e) => setTableView(e.target.value)}>
                <option value="Active">Active</option>
                <option value="Past">Past</option>
                <option value="Booted">Booted</option>
              </select>
              <button className="horizontal_right" id="button_right" onClick={refresh_click_handler}>Refresh</button>
              <div id='activity_indicator_id' className='activityIndicator'>
                <ActivityIndicator size='large' animating={true} color={'#26466F'}/>
              </div>
            </div>
          </div>
          <table id="table1" className="Table-Normal">
            <thead key="thead">
              <tr className="header_wrapper">
                <th onClick={(event) => onTableHeaderClick(event)}>Zone</th>
                <th onClick={(event) => onTableHeaderClick(event)}>Plate</th>
                <th onClick={(event) => onTableHeaderClick(event)}>Time Remaining</th>
                <th onClick={(event) => onTableHeaderClick(event)}>Price</th>
              </tr>
            </thead>  
            <tbody key="tbody" id="table_body">
              <RowValues rows={rows.slice(idx_l, idx_r)} condition={true} key="rowValues"/>
            </tbody>
          </table>
          <div className="horizontal_group">
            <p className="horizontal_left">Page </p>
            <select className="horizontal_left" onChange={(e) => setPage(e.target.value)}>
              <PageSelect page={page} page_max={page_max}/>
            </select>
            <p className="horizontal_left">of {page_max}</p>
            <select defaultValue={"25"} id="items_per_page" className="horizontal_right" onLoad={(e) => setItemsPerPage(e.target.value)} onChange={(e) => setItemsPerPage(e.target.value)}>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
            <p className="horizontal_right"> Items Per Page</p>
          </div>
        </div> {/* table_wrapper */}
        {/* <div id='activity_indicator_id' className='activityIndicator'>
          <ActivityIndicator size='large' animating={true} color={'#26466F'}/>
        </div> */}
      </div>
    </div>
  );
}

export {
  Navbar,
  AdminNavbar,
  SalesNavbar
}

export default LandingPage;